<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="企业名称/企业法人"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="enterpriseName" slot-scope="scope">
          <el-button type="text" @click="$site.view_enterprise_detail(scope.row.enterpriseCode)">{{ scope.row.enterpriseName }}</el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import enterpriseAddoredit from './addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '企业编号', prop: 'enterpriseCode', width: 80 },
          { label: '企业名称', slot: 'enterpriseName' },
          { label: '企业全名', prop: 'enterpriseFullName' },
          { label: '联系人', prop: 'linkPeople' },
          { label: '管理员', prop: 'adminUserName' }
        ],
        operation: {
          width: '320',
          header: { type: 'warning', icon: 'el-icon-plus', label: '企业开户', click: this.add },
          buttons: [
            { type: 'warning', icon: 'el-icon-refresh', label: '重置密码', click: this.resetPWD },
            { type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit },
            { type: 'danger', icon: 'el-icon-delete', label: '删除', click: this.del }
          ]
        }
      },
      condition: {
        keyword: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/list', this.condition)
    },
    async add() {
      await this.$lgh.openDialog({
        ins: enterpriseAddoredit,
        title: '企业开户',
        data: {
          dataInfo: {
            isAdd: true
          }
        }
      })
      this.$lgh.notifySuccess('开户成功', '企业开户成功')
      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: enterpriseAddoredit,
        title: '修改企业信息',
        data: {
          departList: this.departList,
          roleList: this.roleList,
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.notifySuccess('修改成功', '修改企业信息成功')
      this.getPageData()
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除企业信息?', async () => {
        await this.$lgh.get('api/enterprise/delete', {
          enterpriseCode: item.enterpriseCode
        })
        this.$lgh.notifySuccess('删除成功', item.enterpriseName + '信息已删除')
        this.getPageData()
      })
    },
    resetPWD(item) {
      this.$lgh.msgConfirm('确认重置[' + item.adminUserName + ']登录密码?', async () => {
        var r = await this.$lgh.get('api/enterprise/reset/adminpwd', {
          enterpriseCode: item.enterpriseCode,
          adminUserName: item.adminUserName
        })
        this.$lgh.notifySuccess('重置成功', item.adminUserName + '密码已重置为:' + r)
      })
    }
  }
}
</script>
